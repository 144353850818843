import { Badge, colors } from '@everlywell/leaves';
import { MarkdownContent } from 'components/Contentful/MarkdownContent';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import * as S from './styles';
import { CategoryCardProps } from './types';

export const CategoryCard: FC<CategoryCardProps> = ({
  callout,
  cardTitle,
  subHeadline,
  bulletPoints,
  imageDataDesktop,
  imageDataMobile,
  linkPath,
  linkText,
  isInverted,
}) => {
  const handleOnShopClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        label: `${linkText} - ${cardTitle}`,
      },
    });
    navigate(linkPath);
  };

  // Don't show callout if the value from contenful is none
  const hasBadgeCallout = !!callout && callout?.toLocaleLowerCase() !== 'none';

  return (
    <S.CategoryCardWrapper
      hasBadgeCallout={hasBadgeCallout}
      isInverted={isInverted}
    >
      <S.TextWrapper>
        {hasBadgeCallout ? (
          <Badge backgroundColor={colors.teal4} text={callout} />
        ) : null}
        <S.CardInfoWrapper>
          <S.InfoHeadlineContainer hasBadgeCallout={hasBadgeCallout}>
            <S.HeadlineTextContainer>
              <S.HeadlineText>{cardTitle}</S.HeadlineText>
            </S.HeadlineTextContainer>
            <MarkdownContent body={subHeadline} />
          </S.InfoHeadlineContainer>
          <S.InfoTextContainer>
            {bulletPoints.map(
              ({ bulletText, iconAltText, icon: { gatsbyImageData } }, i) => (
                <S.InfoText key={`${iconAltText}-${i}`}>
                  <S.GatsbyImageWrapper>
                    <S.GatsbyImage image={gatsbyImageData} alt="" />
                  </S.GatsbyImageWrapper>

                  <S.BulletMarkdown
                    body={bulletText?.childMarkdownRemark?.html || ''}
                  />
                </S.InfoText>
              ),
            )}
            <S.ShopButtonContainer onClick={handleOnShopClick}>
              <S.ShopButton>{linkText}</S.ShopButton>
            </S.ShopButtonContainer>
          </S.InfoTextContainer>
        </S.CardInfoWrapper>
      </S.TextWrapper>

      <S.ProductImageWrapper>
        <S.DesktopImage>
          <S.GatsbyImage image={imageDataDesktop} alt={cardTitle} />
        </S.DesktopImage>
        <S.MobileImage>
          <S.GatsbyImage image={imageDataMobile} alt={cardTitle} />
        </S.MobileImage>
      </S.ProductImageWrapper>
    </S.CategoryCardWrapper>
  );
};
