import CategorySection from 'components/Homepage/CategorySection';
import { CategorySectionProps } from 'components/Homepage/CategorySection/types';
import React from 'react';

export const CategoryContainer: React.FC<CategorySectionProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  return <CategorySection content={content} />;
};

export default CategoryContainer;
