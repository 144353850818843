import {
  typography,
  size,
  colors,
  mediaQueries,
  InfoBlock as InfoBlockBase,
  TertiaryLink,
} from '@everlywell/leaves';
import { MarkdownContent } from 'components/Contentful/MarkdownContent';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { animated } from 'react-spring';
import styled from 'styled-components';

const InfoBlock = styled(InfoBlockBase).attrs((props) => ({
  imageAlt: props.imageAlt,
}))`
  margin-bottom: ${size.xl1}px;
  background-color: transparent;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    background-color: transparent;
  }

  > div > div {
    background-color: transparent;
  }
`;

const AnimatedBlockContainer = styled(animated.div)`
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 33%;
    &:not(:last-child) {
      padding-right: ${size.lg}px;
    }
  }
`;

const Icon = styled.img`
  display: inline;
  width: ${size.sm}px;
  margin-right: ${size.xs1}px;
`;

const BadgeCallout = styled.div`
  ${typography.buttonTextSmall};
  color: ${colors.green5};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${size.xs1}px;
  border-style: solid;
  background-color: ${colors.green1};
  border: thin solid ${colors.green3};
  border-radius: 5px;
  max-width: fit-content;
`;

const CategoryCardWrapper = styled.div<{
  hasBadgeCallout: boolean;
  isInverted: boolean;
}>`
  display: flex;
  max-height: 420px;
  height: auto;
  width: 100%;
  background: none;
  flex-direction: ${({ isInverted }: { isInverted: boolean }) =>
    isInverted ? 'row' : 'row-reverse'};
  position: relative;
  padding: 0;
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: column;
    max-height: none;
    min-height: 700px;
    padding: 40px ${size.xl2}px ${size.xl2}px;
    background: white;
    margin-right: 0;
    margin-bottom: ${size.xl1}px;
    border-radius: ${size.xs2}px;

    &:last-child {
      margin: 0;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: column;
    min-width: 210px;
  }

  ${mediaQueries.forDesktopUp} {
    min-height: 700px;
    margin-bottom: 0;
    margin-right: 38px;
    padding: ${({ hasBadgeCallout }: { hasBadgeCallout: boolean }) =>
      hasBadgeCallout ? '40px 40px' : '65px 40px 40px'};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TextWrapper = styled.div`
  width: 60%;
  padding: 0 ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 600px;
    width: 100%;
  }
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0;
    flex: none;
    margin-bottom: 60px;
  }
`;

const InfoHeadlineContainer = styled.div<{
  hasBadgeCallout: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: transparent;
  text-align: left;
  font-weight: ${typography.weight.light};
  padding-top: ${({ hasBadgeCallout }: { hasBadgeCallout: boolean }) =>
    hasBadgeCallout ? '8px' : '0'};

  ${AnimatedBlockContainer} {
    &:last-child {
      ${InfoBlock} {
        margin-bottom: 0;
      }
    }
  }
`;

const HeadlineTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const HeadlineText = styled.h3`
  ${typography.h3Text}
  font-size: ${size.lg}px;
  font-weight: ${typography.weight.book};
  line-height: ${size.xl1}px;
  text-align: left;
  color: ${colors.green5};
`;

const InfoTextContainer = styled.div`
  margin-top: ${size.md}px;
`;

const InfoText = styled.div`
  ${typography.buttonTextSmall}
  display: flex;
  font-size: ${size.md}px;
  font-weight: ${typography.weight.bold};
  line-height: ${size.lg}px;
  text-align: left;
  margin-bottom: ${size.sm}px;
`;

const GatsbyImageWrapper = styled.div`
  width: ${size.md}px;
  height: ${size.md}px;
  margin-right: 5px;
  margin-top: ${size.xs2}px;
`;

const GatsbyImage = styled(Img)`
  width: auto;
  height: 100%;
`;

const BulletMarkdown = styled(MarkdownContent)`
  ${typography.buttonTextSmall}
`;

const ShopButtonContainer = styled.div`
  ${typography.buttonTextSmall}
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: ${size.md}px;
  font-size: ${size.md}px;
  font-weight: ${parseInt(typography.weight.bold) + 100};
  cursor: pointer;
  white-space: nowrap;
`;

const ShopButton = styled(TertiaryLink)`
  padding: ${size.sm}px 0;
  color: ${colors.green5};

  &:hover {
    color: ${colors.teal5};
  }
`;

const ProductImageWrapper = styled.div`
  display: flex;
  flex: 1;

  ${mediaQueries.forTabletVerticalUp} {
    flex: none;
  }
`;

const DesktopImage = styled.div`
  display: none;
  ${mediaQueries.forTabletVerticalUp} {
    display: block;
  }
  img {
    ${mediaQueries.forTabletVerticalUp} {
      width: 60%;
      height: auto;
    }

    ${mediaQueries.forTabletHorizontalUp} {
      width: 70%;
    }
  }
`;

const MobileImage = styled.div`
  display: flex;
  align-items: center;
  margin: auto;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }

  img {
    height: 100%;
    width: auto;
  }
`;

export {
  HeadlineText,
  CategoryCardWrapper,
  TextWrapper,
  InfoHeadlineContainer,
  InfoText,
  GatsbyImageWrapper,
  GatsbyImage,
  InfoTextContainer,
  Icon,
  ShopButton,
  ShopButtonContainer,
  BadgeCallout,
  HeadlineTextContainer,
  CardInfoWrapper,
  ProductImageWrapper,
  BulletMarkdown,
  DesktopImage,
  MobileImage,
};
