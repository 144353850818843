import {
  typography,
  size,
  colors,
  mediaQueries,
  Container as LeavesContainer,
  Row,
} from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

export const SectionTitle = styled(RawSectionTitle)`
  text-align: center;
  margin-bottom: ${size.xs1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.sm}px;
  }
`;

const Wrapper = styled(SectionWrapper)`
  background-color: ${colors.green1};
  margin-top: ${size.xl1}px;
`;

const Headline = styled.h2`
  ${typography.h2Text}
  margin: ${size.sm}px ${size.lg}px 36px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl4}px;
  }
`;

const Container = styled(LeavesContainer)`
  padding-right: 0;
  padding-left: 0;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 768px;
    width: 90%;
  }

  ${mediaQueries.forDesktopUp} {
    max-width: 1440px;
  }
`;

/* Custom row without margins becasue the container above has no pading.
This row was overflowing and taking more than a 100% of the screen width */
export const CustomRow = styled(Row)`
  margin: 0;
`;

const InfoBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    align-items: center;
  }

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    align-items: stretch;
    grid-auto-rows: 1fr;
  }
`;

export { Wrapper, Headline, Container, InfoBlockContainer };
