import { Col, size } from '@everlywell/leaves';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated as A, useSpring, config } from 'react-spring';

import { CategoryCard } from './CategoryCard';
import * as S from './styles';
import { CategorySectionProps } from './types';

export const CategorySection: React.FC<CategorySectionProps> = ({
  content,
}) => {
  // TODO: add 100px offset supported by legacy hook if needed
  const [contentCardsEl, inView] = useInView({ triggerOnce: true });

  const titleTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl1}px)`,
    config: config.slow,
  });

  const displayTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: config.slow,
  });

  const { cardsForSection, title, componentName, headline } = content;

  return (
    <S.Wrapper data-testid={componentName} ref={contentCardsEl}>
      <S.Container>
        <S.CustomRow center="xs">
          <Col xs={12} lg={10}>
            <A.div style={titleTransition}>
              <S.SectionTitle text={title} />
            </A.div>
          </Col>
        </S.CustomRow>
        <S.CustomRow center="xs">
          <Col xs={12} lg={10}>
            <A.div style={displayTransition}>
              <S.Headline>{headline}</S.Headline>
            </A.div>
          </Col>
        </S.CustomRow>
        <S.InfoBlockContainer>
          {cardsForSection.map(
            (
              {
                callout,
                cardTitle,
                subHeadline,
                bulletPoints,
                cardImage: { gatsbyImageData: gatsbyImageDataDesktop },
                cardImageMobile: { gatsbyImageData: gatsbyImageDataMobile },
                linkPath,
                linkText,
              },
              i,
            ) => (
              <CategoryCard
                key={`${cardTitle}-${i}`}
                callout={callout}
                cardTitle={cardTitle}
                subHeadline={subHeadline?.childMarkdownRemark?.html || ''}
                bulletPoints={bulletPoints}
                linkPath={linkPath}
                linkText={linkText}
                imageDataDesktop={gatsbyImageDataDesktop}
                imageDataMobile={gatsbyImageDataMobile}
                isInverted={i % 2 === 0}
              />
            ),
          )}
        </S.InfoBlockContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default CategorySection;
